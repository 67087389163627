import { render, staticRenderFns } from "./Offers.vue?vue&type=template&id=5647c5e1"
import script from "./Offers.vue?vue&type=script&setup=true&lang=ts"
export * from "./Offers.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Offers.vue?vue&type=style&index=0&id=5647c5e1&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesTopFixedBar: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/top-fixed-bar/top-fixed-bar.vue').default,MoleculesCategoryDetails: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/category-details/category-details.vue').default,AtomsCtaLabel: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-label/cta-label.vue').default,AtomsCtaLink: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-link/cta-link.vue').default,MoleculesStickySubscribe: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/sticky-subscribe/sticky-subscribe.vue').default,OffersLegalNotice: require('/vercel/path0/components/OffersLegalNotice.vue').default})
